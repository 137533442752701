<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" width="500px" persistent>
        <v-card>
          <v-card-title class="primary">
            <span class="headline white--text"> Editar Contraseña</span>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-form ref="form" v-if="item" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="12" sm="12" class="py-0 px-3 mt-3">
                  <v-text-field dense disabled label="Usuario" v-model="usuario"></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0 px-3 mt-3">
                  <v-text-field
                    dense
                    label="Contraseña"
                    :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showContraseña ? 'text' : 'password'"
                    @click:append="showContraseña = !showContraseña"
                    :rules="[rules.required, rules.numerico, rules.minMax(item.password, 6, 16)]"
                    v-model="item.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0 px-3 mt-3">
                  <v-text-field
                    dense
                    label="Confirmación Contraseña"
                    :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showContraseña ? 'text' : 'password'"
                    @click:append="showContraseña = !showContraseña"
                    :rules="[
                      rules.required,
                      rules.numerico,
                      rules.minMax(item.rulesConfirmPassword, 6, 16),
                      rulesConfirmPassword
                    ]"
                    v-model="item.confirmPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()"> Cancelar </v-btn>
            <v-btn color="primary" text @click="guardar()"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import Swal from 'sweetalert2';
export default {
  name: 'ContrasenaFormulario',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    itemParam: {
      type: Object,
      default: null
    }
  },
  watch: {
    itemParam(val) {
      if (val != null) {
        this.usuario = val.idusuario;
      }
    }
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      minMax: (value, min, max) => {
        if (value) return (value.length >= min && value.length <= max) || `Min: ${min} - Max: ${max} caract.`;
        else return true;
      },
      numerico: (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || 'El campo debe ser numérico';
      }
    },
    showContraseña: false,
    usuario: '',
    showLoading: false,
    valid: true,
    item: null
  }),
  computed: {
    rulesConfirmPassword() {
      return this.item.password == this.item.confirmPassword || 'Las contraseñas no coinciden';
    }
  },
  methods: {
    async close() {
      await this.initializeItem();
      this.$emit('close');
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async guardar() {
      if (this.$refs.form.validate()) {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: '¿Está seguro de guardar los cambios?',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (confirmed.isConfirmed) {
          this.showLoading = true;
          const res = await this.matrizService.post().execResource('cambiarClave', {
            idresponsable: this.usuario,
            password: this.item.password
          });
          this.showLoading = false;
          this.alertDialog(res.status ? 'success' : 'error', res.data);
          if (res.status) this.close();
        }
      }
    },
    async initializeItem() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.usuario = '';
      this.item = {
        password: '',
        confirmPassword: ''
      };
    }
  },
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
    await this.initializeItem();
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
